let fallbackLanguage: string = 'en'
let defaultLanguage: string = 'en'
//pipe için ?? , i18n için Sadece text ile tanımla
const ngbLocalization: { [key: string]: { [langCode: string]: string } } = {
    'ngb.toast.close-aria': {
        'en': 'Close',
        'tr': 'Kapat'
    },
    'ngb.timepicker.AM': {
        'en': '{$INTERPOLATION}',
        'tr': 'ÖÖ'
    },
    'ngb.timepicker.PM': {
        'en': '{$INTERPOLATION}',
        'tr': 'ÖS'
    },
    'ngb.timepicker.decrement-seconds': {
        'en': 'Decrement seconds',
        'tr': 'Saniye azalt'
    },
    'ngb.timepicker.increment-seconds': {
        'en': 'Increment seconds',
        'tr': 'Saniye arttır'
    },
    'ngb.timepicker.seconds': {
        'en': 'Seconds',
        'tr': 'Saniye'
    },
    'ngb.timepicker.SS': {
        'en': 'SS',
        'tr': 'SS'
    },
    'ngb.timepicker.decrement-minutes': {
        'en': 'Decrement minutes',
        'tr': 'Dakika azalt'
    },
    'ngb.timepicker.increment-minutes': {
        'en': 'Increment minutes',
        'tr': 'Dakika arttır'
    },
    'ngb.timepicker.decrement-hours': {
        'en': 'Decrement hours',
        'tr': 'Saat azalt'
    },
    'ngb.timepicker.increment-hours': {
        'en': 'Increment hours',
        'tr': 'Saat arttır'
    },
    'ngb.timepicker.minutes': {
        'en': 'Minutes',
        'tr': 'Dakika'
    },
    'ngb.timepicker.MM': {
        'en': 'MM',
        'tr': 'MM'
    },
    'ngb.timepicker.hours': {
        'en': 'Hours',
        'tr': 'Saat'
    },
    'ngb.timepicker.HH': {
        'en': 'HH',
        'tr': 'HH'
    },
    'ngb.progressbar.value': {
        'en': '{$INTERPOLATION}%',
        'tr': '{$INTERPOLATION}%'
    },
    'ngb.pagination.last-aria': {
        'en': 'Last',
        'tr': 'Son'
    },
    'ngb.pagination.next-aria': {
        'en': 'Next',
        'tr': 'Sonraki'
    },
    'ngb.pagination.previous-aria': {
        'en': 'Previous',
        'tr': 'Önceki'
    },
    'ngb.pagination.first-aria': {
        'en': 'First',
        'tr': 'İlk'
    },
    'ngb.pagination.last': {
        'en': '»»',
        'tr': '»»'
    },
    'ngb.pagination.next': {
        'en': '»',
        'tr': '»'
    },
    'ngb.pagination.previous': {
        'en': '«',
        'tr': '«'
    },
    'ngb.pagination.first': {
        'en': '««',
        'tr': '««'
    },
    'ngb.datepicker.select-year': {
        'en': 'Select year',
        'tr': 'Yıl seç'
    },
    'ngb.datepicker.select-month': {
        'en': 'Select month',
        'tr': 'Ay seç'
    },
    'ngb.datepicker.next-month': {
        'en': 'Next month',
        'tr': 'Sonraki ay'
    },
    'ngb.datepicker.previous-month': {
        'en': 'Previous month',
        'tr': 'Önceki ay'
    },
    'ngb.carousel.next': {
        'en': 'Next',
        'tr': 'Sonraki'
    },
    'ngb.carousel.previous': {
        'en': 'Previous',
        'tr': 'Önceki'
    },
    'ngb.alert.close': {
        'en': 'Close',
        'tr': 'Kapat'
    },
    'datediff.justnow': {
        'en': 'just now',
        'tr': 'az önce'
    },
    'datediff.afew': {
        'en': 'a few seconds ago',
        'tr': 'birkaç saniye önce'
    },
    'datediff.aminute': {
        'en': 'a minute ago',
        'tr': '1 dakika önce'
    },
    'datediff.anhour': {
        'en': 'an hour ago',
        'tr': '1 saat önce'
    },
    'datediff.aday': {
        'en': 'a day ago',
        'tr': '1 gün önce'
    },
    'datediff.amonth': {
        'en': 'a month ago',
        'tr': 'geçen ay'
    },
    'datediff.ayear': {
        'en': 'a year ago',
        'tr': 'geçen yıl'
    },
    'datediff.xday': {
        'en': '{0} days ago',
        'tr': '{0} gün önce'
    },
    'datediff.xhour': {
        'en': '{0} hours ago',
        'tr': '{0} saat önce'
    },
    'datediff.xminute': {
        'en': '{0} minutes ago',
        'tr': '{0} dakika önce'
    },
    'datediff.xmonth': {
        'en': '{0} months ago',
        'tr': '{0} ay önce'
    },
    'datediff.xyear': {
        'en': '{0} years ago',
        'tr': '{0} yıl önce'
    },
    //'': {
    //    'en': '',
    //    'tr': ''
    //},

}

const fileTypeLocalization: { [key: string]: { [langCode: string]: string } } = {
    'fileType.Poster': {
        'en': 'Poster',
        'tr': 'Poster'
    },
    'fileType.Abstract': {
        'en': 'Abstract',
        'tr': 'Özet'
    }, 'fileType.Agenda': {
        'en': 'Agenda',
        'tr': 'Plan'
    }, 'fileType.Agenda (Plan)': {
        'en': 'Agenda',
        'tr': 'Plan'
    }, 'fileType.Undertaking': {
        'en': 'Undertaking',
        'tr': 'Taahhütname'
    }, 'fileType.InvitationLetter': {
        'en': 'BioMarin İnvitation Letter',
        'tr': 'BioMarin Davet Mektubu'
    }, 'fileType.Bill': {
        'en': 'Bill',
        'tr': 'Fatura'
    }, 'fileType.Ticket': {
        'en': 'Ticket',
        'tr': 'Ulaşım Biletleri'
    }, 'fileType.HCPContract': {
        'en': 'HCP Contract',
        'tr': 'Hekim Sözleşmesi'
    }, 'fileType.Contract': {
        'en': 'Contract',
        'tr': 'Sözleşme'
    }, 'fileType.FMV': {
        'en': 'FMV Card',
        'tr': 'FMV Card'
    }, 'fileType.Signature': {
        'en': 'Signature List',
        'tr': 'İmza Listesi'
    }, 'fileType.AgencyBudget': {
        'en': 'Agency Budget Document',
        'tr': 'Acenta Bütçe Dokümanı'
    }, 'fileType.SpeakerInvitation': {
        'en': 'Speaker Invitation',
        'tr': 'Konuşmacı Daveti'
    }, 'fileType.MinistryScreenshot': {
        'en': 'Ministry Notification Screenshot',
        'tr': 'Bakanlık Bildirim Ekran Görüntüsü'
    }, 'fileType.TransferValue': {
        'en': 'Transfer Value Form',
        'tr': 'Değer Aktarım Form'
    }, 'fileType.Sponsorship': {
        'en': 'Sponsorship File',
        'tr': 'Sponsorluk Dosyası'
    }, 'fileType.KVKK': {
        'en': 'GDPR Form',
        'tr': 'KVKK Formu'
    }, 'fileType.Donation': {
        'en': 'Donation Letter',
        'tr': 'Bağış Mektubu'
    },
    'fileType.DonationReceipt': {
        'en': 'Donation Receipt',
        'tr': 'Bağış Makbuzu'
    },
    'fileType.BankReceipt': {
        'en': 'Bank Receipt',
        'tr': 'Banka Makbuzu'
    }, 'fileType.PleaseChoose': {
        'en': '< Please Choose >',
        'tr': '< Lütfen Seçiniz >'
    }, 'Agenda (Plan)': {
        'en': 'Agenda',
        'tr': 'Plan'
    },
    '??Poster': {
        'en': 'Poster',
        'tr': 'Poster'
    },
    '??Abstract (Özet)': {
        'en': 'Abstract',
        'tr': 'Özet'
    },
    '??Agenda (Plan)': {
        'en': 'Agenda',
        'tr': 'Plan'
    },
    '??Undertaking (Taahhütname)': {
        'en': 'Undertaking',
        'tr': 'Taahhütname'
    },
    '??BioMarin Invitation Letter (BioMarin Davet Mektubu)': {
        'en': 'BioMarin İnvitation Letter',
        'tr': 'BioMarin Davet Mektubu'
    },
    '??Bill (Fatura)': {
        'en': 'Bill',
        'tr': 'Fatura'
    },
    '??Ticket (Ulaşım Biletleri)': {
        'en': 'Ticket',
        'tr': 'Ulaşım Biletleri'
    },
    '??HCP Contract (Hekim Sözleşmesi)': {
        'en': 'HCP Contract',
        'tr': 'Hekim Sözleşmesi'
    },
    '??Contract (Sözleşme)': {
        'en': 'Contract',
        'tr': 'Sözleşme'
    },
    '??FMV Card': {
        'en': 'FMV Card',
        'tr': 'FMV Card'
    },
    '??Signature List (İmza Listesi)': {
        'en': 'Signature List',
        'tr': 'İmza Listesi'
    },
    '??Agency Budget Document (Acenta Bütçe Dokümanı)': {
        'en': 'Agency Budget Document',
        'tr': 'Acenta Bütçe Dokümanı'
    },
    '??Speaker Invitation (Konuşmacı Daveti)': {
        'en': 'Speaker Invitation',
        'tr': 'Konuşmacı Daveti'
    },
    '??Ministry Notification Screenshot (Bakanlık Bildirim Ekran Görüntüsü)': {
        'en': 'Ministry Notification Screenshot',
        'tr': 'Bakanlık Bildirim Ekran Görüntüsü'
    },
    '??Transfer Value Form (Değer Aktarım Formu)': {
        'en': 'Transfer Value Form',
        'tr': 'Değer Aktarım Form'
    },
    '??Sponsorship File (Sponsorluk Dosyası)': {
        'en': 'Sponsorship File',
        'tr': 'Sponsorluk Dosyası'
    },
    '??GDPR Form (KVKK Formu)': {
        'en': 'GDPR Form',
        'tr': 'KVKK Formu'
    },
    '??Donation Letter (Bağış Mektubu)': {
        'en': 'Donation Letter',
        'tr': 'Bağış Mektubu'
    },

    '??Agreement': {
        'en': 'Agreement',
        'tr': 'Sözleşme'
    },

    '??Thank You Letter': {
        'en': 'Thank You Letter',
        'tr': 'Teşekkür Mektubu'
    },
}

const localization: { [key: string]: { [langCode: string]: string } } = {
    'DZNcriticalInformationAlert': {
        'en': 'Please make sure that there is no critical information such as patient information in the uploaded excels.',
        'tr': 'Lütfen yüklenen excellerde hasta bilgileri gibi kritik bilgilerin bulunmadığından emin olun.'
    },
    'DZNSmp.1': {
        'en': 'Whole Blood',
        'tr': 'Tüm kan'
    },
    'DZNSmp.3': {
        'en': 'Urine',
        'tr': 'İdrar'
    },
    'Transfer Of Value': {
        'en': 'Transfer Of Value',
        'tr':'Değer Aktarımı'
    },
    'Change User': {
        'en': 'Change User',
        'tr': 'Kullanıcı Değiştir'
    },
    'Return User': {
        'en': 'Return User',
        'tr': 'Geri Dön'
    },
    'Backup User': {
        'en': 'Backup User',
        'tr': 'Vekil Kullanıcı'
    },
    'Remove Backup User': {
        'en': 'Remove Backup User',
        'tr': 'Vekil Kullanıcı Kaldır'
    },
    'Signout': {
        'en': 'Signout',
        'tr': 'Çıkış'
    },
    'Please wait...': {
        'en': 'Please wait...',
        'tr': 'Lütfen bekleyiniz...'
    },

    'User Update': {
        'en': 'User Update',
        'tr': 'Kullanıcı Değiştir'
    },

    'Authorizing user...': {
        'en': 'Authorizing user...',
        'tr': 'Kullanıcı yetkilendiriliyor...'
    },
    'budget.Name': {
        'en': 'Name',
        'tr': 'Bütçe Adı'
    },
    'budget.Currency': {
        'en': 'Currency',
        'tr': 'Döviz'
    },
    'budget.Total': {
        'en': 'Total',
        'tr': 'Toplam'
    },
    'budget.Used': {
        'en': 'Used',
        'tr': 'Kullanılan'
    },
    'budget.Active': {
        'en': 'Active',
        'tr': 'Aktif'
    },
    'budget.Budgets': {
        'en': 'Budgets',
        'tr': 'Bütçeler'
    },

    'dzn.QuotaList': {
        'en': 'Duzen Quota List',
        'tr': 'Düzen Kota Listesi'
    }, 'dzn.Quotas': {
        'en': 'Quotas',
        'tr': 'Kotalar'
    }, 'dzn.Account': {
        'en': 'Account',
        'tr': 'Kurum'
    }, 'dzn.Quota': {
        'en': 'Quota',
        'tr': 'Kota'
    }, 'dzn.Used': {
        'en': 'Used',
        'tr': 'Kullanılan'
    },


    'Home Page': {
        'en': 'Home Page',
        'tr': 'Ana Sayfa'
    },
    'Contacts': {
        'en': 'Contacts',
        'tr': 'Hekimler'
    },
    'Duzen Laboratory': {
        'en': 'Duzen Laboratory',
        'tr': 'Düzen Laboratuvarı'
    },
    'DuzenRequests': {
        'en': 'Requests',
        'tr': 'Talepler'
    },
    'Budgets/List': {
        'en': 'List',
        'tr': 'Liste'
    },
    'Budgets/Details': {
        'en': 'Details',
        'tr': 'Detaylar'
    },
    'request.list.ID': {
        'en': 'ID',
        'tr': 'Numara'
    },
    '??Add HCP': {
        'en': 'HCP Request',
        'tr': 'Hekim Talebi'
    },
    'File Name': {
        'en': 'File Name',
        'tr':'Dosya Adı'
    },
    'Notes': {
        'en': 'Notes',
        'tr': 'Not'
    },
    'File Type': {
        'en': 'File Type',
        'tr': 'Dosya Tipi'
    },
    'Scientific Support Request': {
        'en': 'Scientific Support Request',
        'tr': 'Bilimsel Destek Talebi'
    },
    '??Knowledge Sharing Forum (Bilgi Paylaşım Forumu)': { 'en': 'Knowledge Sharing Forum', 'tr': 'Bilgi Paylaşım Forumu' },
}

const cardTitlesLocalization: { [key: string]: { [langCode: string]: string } } = {
    'REJECTED': {
        'en': 'REJECTED',
        'tr': 'RED EDILDI'
    },
    'PENDING ACTION BY': {
        'en': 'PENDING ACTION BY',
        'tr': 'BEKLEYEN İŞLEM'
    },
    '??CREATED': {
        'en': 'CREATED',
        'tr': 'OLUŞTURULDU'
    },
    '??COURIER INFO': {
        'en': 'COURIER INFO',
        'tr': 'KURYE BİLGİSİ'
    },
    '??AGREEMENT UPLOAD': {
        'en': 'AGREEMENT UPLOAD',
        'tr': 'SÖZLEŞME YÜKLEME'
    },
    '??\'THANK YOU LETTER\' UPLOAD': {
        'en': '\'THANK YOU LETTER\' UPLOAD',
        'tr': '\'TEŞEKKÜR MEKTUBU\' YÜKLEME'
    },
    '??HARD COPY SUBMISSION': {
        'en': 'HARD COPY SUBMISSION',
        'tr': 'BASILI KOPYA TESLİMİ'
    },
    '??COMPLETED': {
        'en': 'COMPLETED',
        'tr': 'TAMAMLANDI'
    },
}

const mainMenuLocalization: { [key: string]: { [langCode: string]: string } } = {
    'Main Page': { 'en': 'Main Page', 'tr': 'Ana Sayfa' },
    'My Contacts': { 'en': 'My Contacts', 'tr': 'Hekimlerim' },
    'Budgets/List': { 'en': 'List', 'tr': 'Liste' },
    'Budgets/Details': { 'en': 'Details', 'tr': 'Detaylar' },
    'DBS Kits': { 'en': 'DBS Kits', 'tr': 'DBS Kitleri' },
    'Infusion Filters': { 'en': 'Infusion Filters', 'tr': 'Infuzyon Filtreleri' },
    'Kuvan Testing Materials': { 'en': 'Kuvan Testing Materials', 'tr': 'Kuvan Test Materyalleri' },
    'Event Requests': { 'en': 'Event Requests', 'tr': 'Aktivite Talepleri' },
    'Congresses': { 'en': 'Congresses', 'tr': 'Kongreler' },
    'Scientific Events': { 'en': 'Scientific Events', 'tr': 'Bilimsel Aktiviteler' },
    'Product Promotion Meetings': { 'en': 'Product Promotion Meetings', 'tr': 'Ürün Tanıtım Toplantıları' },
    'HCP Requests': { 'en': 'HCP Requests', 'tr': 'Hekim Talepleri' },
    'Knowledge Sharing Forums': { 'en': 'Knowledge Sharing Forums', 'tr': 'Bilgi Paylaşım Forumları' },
    'Advisory Boards': { 'en': 'Advisory Boards', 'tr': 'Danışma Kurulları' },
    'Grant & Donations': { 'en': 'Grant & Donations', 'tr': 'Hibe & Bağışlar' },
    'Scientific Support': { 'en': 'Scientific Support', 'tr': 'Bilimsel Destek' },
    'Administration': { 'en': 'Administration', 'tr': 'Yönetim' },
    'Setup Connections': { 'en': 'Setup Connections', 'tr': 'Bağlantı Ayarları' },
    'Request Module Settings': { 'en': 'Request Module Settings', 'tr': 'İstek Modülü Ayarları' },
    'Home Page': { 'en': 'Home Page', 'tr': 'Ana Sayfa' },
    'Sponsorships': { 'en': 'Sponsorships', 'tr': 'Sponsorluklar' },
    'Vendors': { 'en': 'Vendors', 'tr': 'Paydaşlar' },



    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
    //'': { 'en': '', 'tr': '' },
}

const dashboardLocalization: { [key: string]: { [langCode: string]: string } } = {
    'dashboard.events.Header': { 'en': 'Open Events', 'tr': 'Talebe Açık Aktiviteler' },
    'dashboard.pending.HeaderRequest': { 'en': 'Pending Actions (Scientific Support Requests)', 'tr': 'Bekleyen İşlemler (Bilimsel Destek Talepleri)' },
    'dashboard.pending.HeaderTest': { 'en': 'Pending Actions (DBS, Filter, Kuvan)', 'tr': 'Bekleyen İşlemler (DBS, Filter, Kuvan)' },
    'dashboard.budget.Header': { 'en': 'Budget Status', 'tr': 'Bütçe Durumu' },
    'dashboard.events.Empty': { 'en': 'There are no open events', 'tr': 'Açık etkinlik yok' },
    'dashboard.pending.Empty': { 'en': 'There are no pending actions', 'tr': 'Aksiyon bekleyen işlem yok' },
    'dashboard.pending.Action Type': { 'en': 'Action Type', 'tr': 'İşlem Tipi' },
    'dashboard.pending.Details': { 'en': 'Details', 'tr': 'Detaylar' },
    'budget.Remaining': { 'en': 'Remaining', 'tr': 'Kalan' },
    'dashboard.budget.Empty': { 'en': 'There is no active budgets', 'tr': 'Şu anda aktif bir bütçe bulunmuyor' },
    '??Congress (Kongre)': { 'en': 'Congress', 'tr': 'Kongre' },
}

const complete = {...ngbLocalization, ...fileTypeLocalization, ...localization, ...cardTitlesLocalization, ...mainMenuLocalization, ...dashboardLocalization}


let transData: { [key: string]: string }
let currentLang: string = ''

export const getLocalization = (): { [key: string]: string } => {
    let language = getLanguage()
    if (currentLang != language || typeof transData === 'undefined') {

        currentLang = language
        transData = {}
        for (let x in complete) {
            let item = complete[x];
            if (typeof item[language] === 'undefined' && typeof item[fallbackLanguage] === 'undefined') {
                transData[x] = x
            } else if (typeof item[language] === 'undefined') {
                transData[x] = item[fallbackLanguage]
            } else {
                transData[x] = item[language]
            }
        }
    }
    return transData
}

export const setLanguage = (language: string) => {
    let d = new Date();
    d.setTime(d.getTime() + (720 * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = 'scops_language=' + language + ";" + expires + ";path=/";
}

export const getLanguage = (): string => {
    let language: string = defaultLanguage

    let name: string = 'scops_language='
    let ca: string[] = document.cookie.split(';')
    for (let i: number = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            language = c.substring(name.length, c.length);
        }
    }
    return language
}

export const translateString = (value: string) => {
    return value
    //let tr = getLocalization()
    //let vl = tr['??' + value]
    //if (typeof vl === 'undefined') {
    //    console.log('{ \'??' + value +'\': { \'en\': \'\', \'tr\': \'\'},')
    //    console.warn('Translation of (' + value + ') not found')
    //    return value
    //} else {
    //    return vl
    //}
}